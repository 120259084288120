/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from "@tanstack/react-router";

// Import Routes

import { Route as rootRoute } from "./routes/__root";
import { Route as AppImport } from "./routes/_app";
import { Route as AppAccountEditImport } from "./routes/_app/account/edit";
import { Route as AppAccountIndexImport } from "./routes/_app/account/index";
import { Route as AppAccountSettingsLayoutImport } from "./routes/_app/account/settings/_layout";
import { Route as AppAccountSettingsLayoutGeneralImport } from "./routes/_app/account/settings/_layout/general";
import { Route as AppAccountSettingsIndexImport } from "./routes/_app/account/settings/index";
import { Route as AppHomeImport } from "./routes/_app/home";
import { Route as AppPartyPartyIdIndexImport } from "./routes/_app/party/$partyId/index";
import { Route as AuthImport } from "./routes/_auth";
import { Route as AuthLoginImport } from "./routes/_auth/login";
import { Route as AuthSignupImport } from "./routes/_auth/signup";
import { Route as IndexImport } from "./routes/index";

// Create Virtual Routes

const AppAccountSettingsImport = createFileRoute("/_app/account/settings")();

// Create/Update Routes

const AuthRoute = AuthImport.update({
	id: "/_auth",
	getParentRoute: () => rootRoute,
} as any);

const AppRoute = AppImport.update({
	id: "/_app",
	getParentRoute: () => rootRoute,
} as any);

const IndexRoute = IndexImport.update({
	id: "/",
	path: "/",
	getParentRoute: () => rootRoute,
} as any);

const AuthSignupRoute = AuthSignupImport.update({
	id: "/signup",
	path: "/signup",
	getParentRoute: () => AuthRoute,
} as any);

const AuthLoginRoute = AuthLoginImport.update({
	id: "/login",
	path: "/login",
	getParentRoute: () => AuthRoute,
} as any);

const AppHomeRoute = AppHomeImport.update({
	id: "/home",
	path: "/home",
	getParentRoute: () => AppRoute,
} as any);

const AppAccountSettingsRoute = AppAccountSettingsImport.update({
	id: "/account/settings",
	path: "/account/settings",
	getParentRoute: () => AppRoute,
} as any);

const AppAccountIndexRoute = AppAccountIndexImport.update({
	id: "/account/",
	path: "/account/",
	getParentRoute: () => AppRoute,
} as any);

const AppAccountEditRoute = AppAccountEditImport.update({
	id: "/account/edit",
	path: "/account/edit",
	getParentRoute: () => AppRoute,
} as any);

const AppPartyPartyIdIndexRoute = AppPartyPartyIdIndexImport.update({
	id: "/party/$partyId/",
	path: "/party/$partyId/",
	getParentRoute: () => AppRoute,
} as any).lazy(() =>
	import("./routes/_app/party/$partyId/index.lazy").then((d) => d.Route),
);

const AppAccountSettingsIndexRoute = AppAccountSettingsIndexImport.update({
	id: "/",
	path: "/",
	getParentRoute: () => AppAccountSettingsRoute,
} as any);

const AppAccountSettingsLayoutRoute = AppAccountSettingsLayoutImport.update({
	id: "/_layout",
	getParentRoute: () => AppAccountSettingsRoute,
} as any);

const AppAccountSettingsLayoutGeneralRoute =
	AppAccountSettingsLayoutGeneralImport.update({
		id: "/general",
		path: "/general",
		getParentRoute: () => AppAccountSettingsLayoutRoute,
	} as any);

// Populate the FileRoutesByPath interface

declare module "@tanstack/react-router" {
	interface FileRoutesByPath {
		"/": {
			id: "/";
			path: "/";
			fullPath: "/";
			preLoaderRoute: typeof IndexImport;
			parentRoute: typeof rootRoute;
		};
		"/_app": {
			id: "/_app";
			path: "";
			fullPath: "";
			preLoaderRoute: typeof AppImport;
			parentRoute: typeof rootRoute;
		};
		"/_auth": {
			id: "/_auth";
			path: "";
			fullPath: "";
			preLoaderRoute: typeof AuthImport;
			parentRoute: typeof rootRoute;
		};
		"/_app/home": {
			id: "/_app/home";
			path: "/home";
			fullPath: "/home";
			preLoaderRoute: typeof AppHomeImport;
			parentRoute: typeof AppImport;
		};
		"/_auth/login": {
			id: "/_auth/login";
			path: "/login";
			fullPath: "/login";
			preLoaderRoute: typeof AuthLoginImport;
			parentRoute: typeof AuthImport;
		};
		"/_auth/signup": {
			id: "/_auth/signup";
			path: "/signup";
			fullPath: "/signup";
			preLoaderRoute: typeof AuthSignupImport;
			parentRoute: typeof AuthImport;
		};
		"/_app/account/edit": {
			id: "/_app/account/edit";
			path: "/account/edit";
			fullPath: "/account/edit";
			preLoaderRoute: typeof AppAccountEditImport;
			parentRoute: typeof AppImport;
		};
		"/_app/account/": {
			id: "/_app/account/";
			path: "/account";
			fullPath: "/account";
			preLoaderRoute: typeof AppAccountIndexImport;
			parentRoute: typeof AppImport;
		};
		"/_app/account/settings": {
			id: "/_app/account/settings";
			path: "/account/settings";
			fullPath: "/account/settings";
			preLoaderRoute: typeof AppAccountSettingsImport;
			parentRoute: typeof AppImport;
		};
		"/_app/account/settings/_layout": {
			id: "/_app/account/settings/_layout";
			path: "/account/settings";
			fullPath: "/account/settings";
			preLoaderRoute: typeof AppAccountSettingsLayoutImport;
			parentRoute: typeof AppAccountSettingsRoute;
		};
		"/_app/account/settings/": {
			id: "/_app/account/settings/";
			path: "/";
			fullPath: "/account/settings/";
			preLoaderRoute: typeof AppAccountSettingsIndexImport;
			parentRoute: typeof AppAccountSettingsImport;
		};
		"/_app/party/$partyId/": {
			id: "/_app/party/$partyId/";
			path: "/party/$partyId";
			fullPath: "/party/$partyId";
			preLoaderRoute: typeof AppPartyPartyIdIndexImport;
			parentRoute: typeof AppImport;
		};
		"/_app/account/settings/_layout/general": {
			id: "/_app/account/settings/_layout/general";
			path: "/general";
			fullPath: "/account/settings/general";
			preLoaderRoute: typeof AppAccountSettingsLayoutGeneralImport;
			parentRoute: typeof AppAccountSettingsLayoutImport;
		};
	}
}

// Create and export the route tree

interface AppAccountSettingsLayoutRouteChildren {
	AppAccountSettingsLayoutGeneralRoute: typeof AppAccountSettingsLayoutGeneralRoute;
}

const AppAccountSettingsLayoutRouteChildren: AppAccountSettingsLayoutRouteChildren =
	{
		AppAccountSettingsLayoutGeneralRoute: AppAccountSettingsLayoutGeneralRoute,
	};

const AppAccountSettingsLayoutRouteWithChildren =
	AppAccountSettingsLayoutRoute._addFileChildren(
		AppAccountSettingsLayoutRouteChildren,
	);

interface AppAccountSettingsRouteChildren {
	AppAccountSettingsLayoutRoute: typeof AppAccountSettingsLayoutRouteWithChildren;
	AppAccountSettingsIndexRoute: typeof AppAccountSettingsIndexRoute;
}

const AppAccountSettingsRouteChildren: AppAccountSettingsRouteChildren = {
	AppAccountSettingsLayoutRoute: AppAccountSettingsLayoutRouteWithChildren,
	AppAccountSettingsIndexRoute: AppAccountSettingsIndexRoute,
};

const AppAccountSettingsRouteWithChildren =
	AppAccountSettingsRoute._addFileChildren(AppAccountSettingsRouteChildren);

interface AppRouteChildren {
	AppHomeRoute: typeof AppHomeRoute;
	AppAccountEditRoute: typeof AppAccountEditRoute;
	AppAccountIndexRoute: typeof AppAccountIndexRoute;
	AppAccountSettingsRoute: typeof AppAccountSettingsRouteWithChildren;
	AppPartyPartyIdIndexRoute: typeof AppPartyPartyIdIndexRoute;
}

const AppRouteChildren: AppRouteChildren = {
	AppHomeRoute: AppHomeRoute,
	AppAccountEditRoute: AppAccountEditRoute,
	AppAccountIndexRoute: AppAccountIndexRoute,
	AppAccountSettingsRoute: AppAccountSettingsRouteWithChildren,
	AppPartyPartyIdIndexRoute: AppPartyPartyIdIndexRoute,
};

const AppRouteWithChildren = AppRoute._addFileChildren(AppRouteChildren);

interface AuthRouteChildren {
	AuthLoginRoute: typeof AuthLoginRoute;
	AuthSignupRoute: typeof AuthSignupRoute;
}

const AuthRouteChildren: AuthRouteChildren = {
	AuthLoginRoute: AuthLoginRoute,
	AuthSignupRoute: AuthSignupRoute,
};

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren);

export interface FileRoutesByFullPath {
	"/": typeof IndexRoute;
	"": typeof AuthRouteWithChildren;
	"/home": typeof AppHomeRoute;
	"/login": typeof AuthLoginRoute;
	"/signup": typeof AuthSignupRoute;
	"/account/edit": typeof AppAccountEditRoute;
	"/account": typeof AppAccountIndexRoute;
	"/account/settings": typeof AppAccountSettingsLayoutRouteWithChildren;
	"/account/settings/": typeof AppAccountSettingsIndexRoute;
	"/party/$partyId": typeof AppPartyPartyIdIndexRoute;
	"/account/settings/general": typeof AppAccountSettingsLayoutGeneralRoute;
}

export interface FileRoutesByTo {
	"/": typeof IndexRoute;
	"": typeof AuthRouteWithChildren;
	"/home": typeof AppHomeRoute;
	"/login": typeof AuthLoginRoute;
	"/signup": typeof AuthSignupRoute;
	"/account/edit": typeof AppAccountEditRoute;
	"/account": typeof AppAccountIndexRoute;
	"/account/settings": typeof AppAccountSettingsIndexRoute;
	"/party/$partyId": typeof AppPartyPartyIdIndexRoute;
	"/account/settings/general": typeof AppAccountSettingsLayoutGeneralRoute;
}

export interface FileRoutesById {
	__root__: typeof rootRoute;
	"/": typeof IndexRoute;
	"/_app": typeof AppRouteWithChildren;
	"/_auth": typeof AuthRouteWithChildren;
	"/_app/home": typeof AppHomeRoute;
	"/_auth/login": typeof AuthLoginRoute;
	"/_auth/signup": typeof AuthSignupRoute;
	"/_app/account/edit": typeof AppAccountEditRoute;
	"/_app/account/": typeof AppAccountIndexRoute;
	"/_app/account/settings": typeof AppAccountSettingsRouteWithChildren;
	"/_app/account/settings/_layout": typeof AppAccountSettingsLayoutRouteWithChildren;
	"/_app/account/settings/": typeof AppAccountSettingsIndexRoute;
	"/_app/party/$partyId/": typeof AppPartyPartyIdIndexRoute;
	"/_app/account/settings/_layout/general": typeof AppAccountSettingsLayoutGeneralRoute;
}

export interface FileRouteTypes {
	fileRoutesByFullPath: FileRoutesByFullPath;
	fullPaths:
		| "/"
		| ""
		| "/home"
		| "/login"
		| "/signup"
		| "/account/edit"
		| "/account"
		| "/account/settings"
		| "/account/settings/"
		| "/party/$partyId"
		| "/account/settings/general";
	fileRoutesByTo: FileRoutesByTo;
	to:
		| "/"
		| ""
		| "/home"
		| "/login"
		| "/signup"
		| "/account/edit"
		| "/account"
		| "/account/settings"
		| "/party/$partyId"
		| "/account/settings/general";
	id:
		| "__root__"
		| "/"
		| "/_app"
		| "/_auth"
		| "/_app/home"
		| "/_auth/login"
		| "/_auth/signup"
		| "/_app/account/edit"
		| "/_app/account/"
		| "/_app/account/settings"
		| "/_app/account/settings/_layout"
		| "/_app/account/settings/"
		| "/_app/party/$partyId/"
		| "/_app/account/settings/_layout/general";
	fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
	IndexRoute: typeof IndexRoute;
	AppRoute: typeof AppRouteWithChildren;
	AuthRoute: typeof AuthRouteWithChildren;
}

const rootRouteChildren: RootRouteChildren = {
	IndexRoute: IndexRoute,
	AppRoute: AppRouteWithChildren,
	AuthRoute: AuthRouteWithChildren,
};

export const routeTree = rootRoute
	._addFileChildren(rootRouteChildren)
	._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_app",
        "/_auth"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_app": {
      "filePath": "_app.tsx",
      "children": [
        "/_app/home",
        "/_app/account/edit",
        "/_app/account/",
        "/_app/account/settings",
        "/_app/party/$partyId/"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/login",
        "/_auth/signup"
      ]
    },
    "/_app/home": {
      "filePath": "_app/home.tsx",
      "parent": "/_app"
    },
    "/_auth/login": {
      "filePath": "_auth/login.tsx",
      "parent": "/_auth"
    },
    "/_auth/signup": {
      "filePath": "_auth/signup.tsx",
      "parent": "/_auth"
    },
    "/_app/account/edit": {
      "filePath": "_app/account/edit.tsx",
      "parent": "/_app"
    },
    "/_app/account/": {
      "filePath": "_app/account/index.tsx",
      "parent": "/_app"
    },
    "/_app/account/settings": {
      "filePath": "_app/account/settings",
      "parent": "/_app",
      "children": [
        "/_app/account/settings/_layout",
        "/_app/account/settings/"
      ]
    },
    "/_app/account/settings/_layout": {
      "filePath": "_app/account/settings/_layout.tsx",
      "parent": "/_app/account/settings",
      "children": [
        "/_app/account/settings/_layout/general"
      ]
    },
    "/_app/account/settings/": {
      "filePath": "_app/account/settings/index.tsx",
      "parent": "/_app/account/settings"
    },
    "/_app/party/$partyId/": {
      "filePath": "_app/party/$partyId/index.tsx",
      "parent": "/_app"
    },
    "/_app/account/settings/_layout/general": {
      "filePath": "_app/account/settings/_layout/general.tsx",
      "parent": "/_app/account/settings/_layout"
    }
  }
}
ROUTE_MANIFEST_END */
