import { useApi } from "@/components/context/ApiContext";
import { useSession } from "@/components/context/SessionContext";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Link, createFileRoute, useNavigate } from "@tanstack/react-router";
import { useReducer } from "react";
import toast from "react-hot-toast";

type TAccountReducerState = {
	username: string;
};

type TAccountReducerAction = { type: "set_username"; username: string };

const useAccountReducer = (defaults: TAccountReducerState) => {
	return useReducer(
		(state: TAccountReducerState, action: TAccountReducerAction) => {
			switch (action.type) {
				case "set_username":
					return { ...state, username: action.username };
				default:
					return state;
			}
		},
		defaults,
	);
};

export const Route = createFileRoute("/_app/account/edit")({
	errorComponent: () => (
		<div className="flex flex-col">
			You must be logged in to view this page
			<Link to="/login">
				<button className="btn btn-primary">Login</button>
			</Link>
		</div>
	),

	component: () => {
		const { user } = useSession();

		if (!user) {
			throw new Error("No user found");
		}

		const [accountData, dispatch] = useAccountReducer(user);

		const queryClient = useQueryClient();

		const navigate = useNavigate();
		const { api } = useApi();

		const mutation = useMutation({
			mutationFn: async (data: TAccountReducerState) => {
				// console.log('mutation', data);

				await toast.promise(api.put("account", { json: data }), {
					loading: "Saving...",
					success: "Saved",
					error: "Error",
				});
			},
			onSettled: async () => {
				navigate({ to: "/account" }).catch(toast.error);
				queryClient
					.invalidateQueries({
						queryKey: ["session"],
					})
					.catch(console.error);
			},
		});

		return (
			<div className={"mt-8 flex flex-col items-center px-4"}>
				<Card
					className={
						"card w-full bg-base-200 shadow-lg sm:w-10/12 md:w-8/12 lg:w-7/12 2xl:w-5/12"
					}
				>
					<CardContent className={"card-body flex flex-col items-center"}>
						<Avatar className={"mt-8 h-20 w-20"}>
							<AvatarImage src="https://github.com/shadcn.png" alt="@shadcn" />
							<AvatarFallback>CN</AvatarFallback>
						</Avatar>
						<div className={"flex w-full flex-col items-start"}>
							<Input
								value={accountData.username}
								onChange={(e) => {
									dispatch({ type: "set_username", username: e.target.value });
								}}
								className="my-6 font-bold text-2xl"
							/>
						</div>
						-- todo
						<div className="flex w-full flex-col">
							<h3>Linked Accounts:</h3>
							<ul>
								{user?.linkedAccounts.map((account) => (
									<li key="account">
										{account.replace(
											/\w\S*/g,
											(text) =>
												text.charAt(0).toUpperCase() +
												text.substring(1).toLowerCase(),
										)}
									</li>
								))}
							</ul>
						</div>
						<div className="">
							<Button
								onClick={async () => {
									await mutation.mutateAsync(accountData);
								}}
								className=""
							>
								Save
							</Button>
						</div>
					</CardContent>
				</Card>
			</div>
		);
	},
});
