"use client";

import { useApi } from "@/components/context/ApiContext";
import { Button } from "@/components/ui/button";
import { CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import type { paths } from "@repo/apitypes";
import { useQueryClient } from "@tanstack/react-query";
import { Link, createFileRoute } from "@tanstack/react-router";
import type { HTTPError } from "ky";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

export const Route = createFileRoute("/_auth/signup")({ component: Signup });

const formSchema = z.object({
	email: z.string().email(),
	password: z
		.string()
		.min(6, { message: "Password must be at least 6 characters" }),
});

export default function Signup() {
	// const [state, formAction] = useFormState(action, initialState);

	const queryClient = useQueryClient();

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		mode: "onBlur",
		reValidateMode: "onChange",
		defaultValues: {
			email: "",
			password: "",
		},
	});

	const { api, setCsrfToken } = useApi();

	const handleSubmit = async (data: z.infer<typeof formSchema>) => {
		const email = data.email;
		const password = data.password;
		// formAction({ type: 'submit', username, password });

		const promise = toast.promise(
			api.post("auth/signup", { json: { email: email, password } }),
			{
				loading: "Creating account...",
				success: "Account created successfully",
				error: (err: HTTPError) => {
					console.debug(err.response);
					if (err.response.status === 406) {
						form.setError("email", { message: "Email in use" });
						return "Email in use";
					}

					return err.response.statusText || "Error creating account";
				},
			},
		);
		const res = await promise;
		if (res.ok) {
			setCsrfToken(
				(
					(await res.json()) as paths["/auth/signup"]["post"]["responses"]["201"]["content"]["application/json"]
				).csrfToken ?? "",
			);
			queryClient
				.invalidateQueries({ queryKey: ["session"] })
				.catch(console.error);
			// window.location.href = '/dashboard';
			// navigate({ to: '/home' });
		}
	};

	return (
		<>
			<CardHeader>
				<CardTitle>Create Account to get Started!</CardTitle>
			</CardHeader>
			{/* {state?.error} */}
			<CardContent>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(handleSubmit)} className="">
						<FormField
							control={form.control}
							name={"email"}
							render={({ field }) => {
								return (
									<FormItem>
										<FormLabel>Email</FormLabel>
										<FormControl>
											<Input {...field} placeholder={"email"} />
										</FormControl>
										<FormMessage />
									</FormItem>
								);
							}}
						/>

						<FormField
							name={"password"}
							control={form.control}
							render={({ field }) => {
								return (
									<FormItem className={"mt-4"}>
										<FormLabel>Password</FormLabel>
										<FormControl>
											<Input
												{...field}
												placeholder="password"
												type={"password"}
												// className='input input-bordered'
												required
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								);
							}}
						/>
						<FormMessage />
						<Button type="submit" className="mt-4">
							Submit
						</Button>
					</form>
				</Form>
				<span className="float-right mb-2">
					Already have an account?{" "}
					<Button asChild variant={"ghost"}>
						<Link className="" to={"/login"}>
							Login
						</Link>
					</Button>
				</span>
			</CardContent>
		</>
	);
}
