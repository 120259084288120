import { Button } from "@/components/ui/button";
import { VscGithubInverted } from "react-icons/vsc";

export default function Footer() {
	return (
		<footer
			className={
				"mt-auto flex flex-row items-center border-t p-2 text-neutral-800 text-sm dark:border-t-zinc-900 dark:text-neutral-500"
			}
		>
			© {new Date().getFullYear()}{" "}
			<Button
				variant={"link"}
				className={"pl-1.5 text-neutral-800 text-sm dark:text-neutral-500"}
			>
				<a href={"https://adamhodgkinson.dev"} className={""}>
					Adam Hodgkinson
				</a>
			</Button>
			<a
				href={"https://github.com/ahodgk"}
				className={"text-lg transition-all ease-in-out hover:text-neutral-700"}
			>
				<VscGithubInverted />
			</a>
		</footer>
	);
}
