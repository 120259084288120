import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";

import { SessionProvider } from "./components/context/SessionContext";

import { Toaster } from "react-hot-toast";

import { createRouter } from "@tanstack/react-router";
import { ApiProvider, baseapi } from "./components/context/ApiContext";
import { DebugProvider, useDebug } from "./components/context/DebugContext";
import { routeTree } from "./routeTree.gen";
import AppRouter from "./router";

// Sentry.init({
//   dsn: 'https://a8df42d8bad66aa414e48464bda7d4ba@o251649.ingest.us.sentry.io/4508252794847232',
//   integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

console.info("Starting app");

// Create a client
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 1000 * 60 * 5,
			retry: 2,
		},
	},
});

queryClient.prefetchQuery({
	queryKey: ["session"],
	queryFn: async () => {
		// await new Promise<void>((resolve) =>
		//   setTimeout(() => {
		//     resolve();
		//   }, 5000)
		// );
		return await baseapi
			.get("auth/me", { cache: "no-cache", credentials: "include" })
			.json();
	},
	staleTime: 1000 * 60 * 5,
});

// Create a new router instance
const router = createRouter({
	routeTree,
	defaultPendingComponent: LoadingComponent,
	defaultNotFoundComponent: NotFoundComponent,
	defaultErrorComponent: ErrorComponent,
	context: {
		user: null,
		session: null,
		logout: async () => undefined,
		queryClient,
	},
});
// Register the router instance for type safety
declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router;
	}
}

const theme = localStorage.getItem("theme") === "light" ? "light" : "dark";
document.getElementsByTagName("html")[0]?.setAttribute("data-theme", theme);

// biome-ignore lint/style/noNonNullAssertion: <explanation>
createRoot(document.getElementById("root")!).render(
	<ApiProvider>
		{theme === "dark" ? (
			<>
				<meta name="background-color" content="#080808" />
				<meta name="theme-color" content="#080808" />
			</>
		) : (
			<meta name="theme-color" content="#ffffff" />
		)}

		{/* <link href={`${import.meta.env.VITE_API_URL}/manifest.json`} rel='manifest' /> */}

		<StrictMode>
			<Sentry.ErrorBoundary
				fallback={({ error }) => {
					const err = error as Error;
					console.debug(error);
					if (err.message.includes("Server version mismatch")) {
						setTimeout(() => {
							window.location.reload;
						}, 5 * 1000);
						return (
							<div>
								Server version mismatch. App is likely undergoing maintenance,
								please try again later.
								<br />
								This page will try to reload in 60 seconds.
							</div>
						);
					}
					return (
						<div>
							App is likely in maintenance mode, please try again later...{" "}
							<br />
							{err.message}
						</div>
					);
				}}
			>
				<DebugProvider>
					<Suspense fallback={<LoadingComponent />}>
						<QueryClientProvider client={queryClient}>
							<Toaster
								toastOptions={{
									className:
										"dark:bg-zinc-950 dark:text-white dark:shadow-zinc-800 dark:shadow",
								}}
							/>
							<SessionProvider>
								<AppRouter router={router} />
							</SessionProvider>
						</QueryClientProvider>
					</Suspense>
				</DebugProvider>
			</Sentry.ErrorBoundary>
		</StrictMode>
	</ApiProvider>,
);

function ErrorComponent({ error, reset }: { error; reset }) {
	return (
		<div className="absolute/ inset-0 flex h-full w-full grow flex-col items-center justify-center bg-inherit text-xl">
			Uh oh, something went wrong{" "}
			<button className="btn btn-warning" onClick={reset} type="button">
				Ignore
			</button>
			Try reloading the page
			<div
				tabIndex={0}
				className="collapse-plus collapse mt-8 w-fit border border-error "
			>
				<div className="collapse-title font-medium text-xl">
					Click to see more details
				</div>
				<div className="collapse-content">
					<p>{error.message}</p>
					<br />
					<pre>{JSON.stringify(error, null, 2)}</pre>
				</div>
			</div>
		</div>
	);
}

function NotFoundComponent() {
	return (
		<div className="inset-0 flex h-full w-full flex-col items-center justify-center bg-inherit text-xl">
			<span className="collapse">Not found</span>
		</div>
	);
}

function LoadingComponent(props) {
	const { debug } = useDebug();
	return (
		<div className="absolute/ inset-0 my-auto flex h-full w-full flex-col items-center justify-center bg-inherit text-xl">
			<span>Loading</span>
			<span className="loading loading-spinner text-secondary" />
			{debug && `props\n${JSON.stringify(props)}`}
		</div>
	);
}
