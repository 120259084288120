import { useApi } from "@/components/context/ApiContext";
import { Button } from "@/components/ui/button";
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "@/components/ui/card";
import {
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { getPartyList } from "@/lib/queries/getPartyList";
import { zodResolver } from "@hookform/resolvers/zod";
import type { paths } from "@repo/apitypes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

const formSchema = z.object({
	groupName: z.string().min(3).max(50),
});

type TCreateGroupResponse =
	paths["/party"]["post"]["responses"]["200"]["content"]["application/json"];
type TCreateGroupRequest =
	paths["/party"]["post"]["requestBody"]["content"]["application/json"];

export const CreateGroupCard = () => {
	const { api } = useApi();
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		mode: "onBlur",
		reValidateMode: "onChange",
		defaultValues: {
			groupName: "",
		},
	});

	const navigate = useNavigate();

	const queryClient = useQueryClient();

	const createPartyMutation = useMutation({
		mutationFn: async (data: TCreateGroupRequest) => {
			return await api
				.post<TCreateGroupResponse>("party", { json: data })
				.json();
		},
		onSettled: async (_data) => {
			queryClient
				.invalidateQueries({ queryKey: getPartyList({}).queryKey.slice(0, 1) })
				.catch(console.warn);
		},
		onError: (error) => {
			toast.error("Error creating party");
			console.error("error", error);
		},

		onSuccess: async (data) => {
			const { id } = data.party;
			await navigate({ to: `/party/${id}` });
		},
	});

	const handleSubmit = async (data: z.infer<typeof formSchema>) => {
		// console.log('data', data);
		await createPartyMutation.mutateAsync({ name: data.groupName });
		return;
	};

	return (
		<Card className={"min-w-96"}>
			<CardHeader>
				<CardTitle>Create Group</CardTitle>
				<CardDescription>Create movie finder group</CardDescription>
			</CardHeader>
			<CardContent>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(handleSubmit)} className={""}>
						<FormField
							control={form.control}
							name={"groupName"}
							render={({ field }) => (
								<FormItem>
									<FormLabel>
										{" "}
										Group Name
										<FormControl>
											<Input placeholder="Movie Gang!" {...field} />
										</FormControl>
									</FormLabel>
									<FormDescription>
										This is the name of your party
									</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>
						<Button className={"mt-4"}>Create Group</Button>
					</form>
				</Form>
			</CardContent>
		</Card>
	);
};
