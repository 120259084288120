import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { getPartyList } from "@/lib/queries/getPartyList";
import { CreateGroupCard } from "@/routes/_app/-CreateGroupCard";
import ListGroupsCard from "@/routes/_app/-ListGroupsCard";
import { createFileRoute } from "@tanstack/react-router";
import _short from "short-uuid";

const short = _short();
type SearchParams = {
	page?: number;
	limit?: number;
};

export const Route = createFileRoute("/_app/home")({
	beforeLoad: async ({ context: { queryClient }, search: { page, limit } }) => {
		queryClient
			.prefetchQuery({ ...getPartyList({ page, limit }) })
			.catch(console.error);
	},

	validateSearch: (searchParams: SearchParams): SearchParams => {
		const s = {
			page: Number(searchParams.page) || 1,
			limit: Number(searchParams.limit) || 12,
		};
		if (s.page < 1) s.page = 1;
		if (s.limit < 1) s.limit = 1;
		if (s.limit > 100) s.limit = 100;
		return s;
	},

	component: RouteComponent,
});

function RouteComponent() {
	return (
		<div className="mx-auto mt-4 flex w-full flex-col items-center px-4 pb-8 md:w-3/4 lg:w-2/3 xl:w-1/2">
			<Tabs defaultValue="create" className="w-full ">
				<TabsList className="">
					<TabsTrigger value="create">Create</TabsTrigger>
					<TabsTrigger value="join">Join</TabsTrigger>
				</TabsList>
				<TabsContent value="create" className="">
					<CreateGroupCard />
				</TabsContent>
				<TabsContent value="join" className="">
					<Card>
						<CardHeader>
							<CardTitle>Join a party!</CardTitle>
						</CardHeader>
						<CardContent>
							{/*<p>Join a party by entering the party code below.</p>*/}
							{/*<Input*/}
							{/*  type='text'*/}
							{/*  placeholder='Party code'*/}
							{/*  className='w-full p-2 border border-gray-300 rounded'*/}
							{/*/>*/}
							<p>
								To join a party, get the party creator to send you the invite
								link
							</p>
						</CardContent>
					</Card>
				</TabsContent>
			</Tabs>
			<ListGroupsCard />
		</div>
	);
}
