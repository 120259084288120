import {
	type ReactNode,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";

export type DebugContextType = {
	debug: boolean;
	setDebugEnabled: (enabled: boolean) => void;
};

export const DebugContext = createContext<DebugContextType>({
	debug: localStorage.getItem("debug") === "true",
	setDebugEnabled: () => {
		console.warn("setDebugEnabled called without initialised session manager");
	},
});

export const useDebug = () => {
	return useContext(DebugContext);
};

export function DebugProvider({
	children,
}: { children: ReactNode | ReactNode[] }) {
	const [debugEnabled, setDebugEnabledState] = useState(
		localStorage.getItem("debug") === "true",
	);
	const setDebugEnabled = useCallback((b: boolean) => {
		localStorage.setItem("debug", b ? "true" : "false");
		setDebugEnabledState(b);
	}, []);
	useEffect(() => {
		// @ts-expect-error adding debug mode option to window object, cba to figure out how to declare the updated type
		window.setsipsyncdebugmode = setDebugEnabled;
	}, [setDebugEnabled]);

	return (
		<div>
			<DebugContext.Provider
				value={{
					debug: debugEnabled,
					setDebugEnabled,
				}}
			>
				{children}
			</DebugContext.Provider>
		</div>
	);
}
