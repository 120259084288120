import { baseapi } from "@/components/context/ApiContext";
import type { paths } from "@repo/apitypes";

export const getPartyQueryOptions = (partyId: string) => ({
	queryKey: ["party", partyId],
	queryFn: async () => {
		return (await baseapi
			.get(`party/${partyId}`)
			.then((res) =>
				res.json(),
			)) as paths["/party/{id}"]["get"]["responses"]["200"]["content"]["application/json"];
	},
});
