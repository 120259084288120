import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { CreateGroupCard } from "@/routes/-CreateGroupCard";

import { Input } from "@/components/ui/input";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_app/home")({
	component: () => (
		<div className="mt-4 flex w-full flex-col items-center">
			<Tabs
				defaultValue="create"
				className="w-full px-4 md:w-3/4 lg:w-2/3 xl:w-1/2"
			>
				<TabsList className="">
					<TabsTrigger value="create">Create</TabsTrigger>
					<TabsTrigger value="join">Join</TabsTrigger>
				</TabsList>
				<TabsContent value="create" className="">
					<CreateGroupCard />
				</TabsContent>
				<TabsContent value="join" className="">
					<Card>
						<CardHeader>
							<CardTitle>Join a party!</CardTitle>
						</CardHeader>
						<CardContent>
							{/*<p>Join a party by entering the party code below.</p>*/}
							{/*<Input*/}
							{/*  type='text'*/}
							{/*  placeholder='Party code'*/}
							{/*  className='w-full p-2 border border-gray-300 rounded'*/}
							{/*/>*/}
							<p>
								To join a party, get the party creator to send you the invite
								link
							</p>
						</CardContent>
					</Card>
				</TabsContent>
			</Tabs>
			aa
		</div>
	),
});
