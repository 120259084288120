import TabList from "@/routes/_app/account/settings/-TabList";
import { Outlet, createFileRoute } from "@tanstack/react-router";
import type { ReactNode } from "react";

export const Route = createFileRoute("/_app/account/settings/_layout")({
	component: Layout,
});

export default function Layout({ children }: { children: ReactNode }) {
	return (
		<div className="flex flex-col flex-nowrap">
			<TabList />
			<div>
				<Outlet />
			</div>
			<div>{children}</div>
		</div>
	);
}
