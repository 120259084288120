import { useApi } from "@/components/context/ApiContext";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "@/components/ui/card";
import {
	Pagination,
	PaginationContent,
	PaginationEllipsis,
	PaginationItem,
	PaginationLink,
	PaginationNext,
	PaginationPrevious,
} from "@/components/ui/pagination";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import { type PartyList, getPartyList } from "@/lib/queries/getPartyList";
import { timeSince } from "@/lib/utils";
import { Route } from "@/routes/_app/home";
import {
	type UseMutationResult,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { Link, useNavigate } from "@tanstack/react-router";
import toast from "react-hot-toast";
import _short from "short-uuid";

const short = _short();
export default function ListGroupsCard() {
	const { page: _page, limit: _limit } = Route.useSearch();
	const page = _page ?? 1;
	const limit = _limit ?? 12;

	const navigate = useNavigate();

	const { data, isPending } = useQuery({
		...getPartyList({ page, limit }),
	});
	const maxPages =
		!isPending && data ? Math.ceil(data.totalParties / limit) : 0;
	if (page > maxPages && maxPages > 0) {
		navigate({ to: ".", search: { page: maxPages, limit } });
	}
	const { api } = useApi();
	const queryClient = useQueryClient();
	const deletePartyMutation = useMutation({
		mutationFn: async (partyId: string) => {
			await api.delete(`party/${partyId}`);
		},
		onMutate: async (partyId: string) => {
			const previousData = queryClient.getQueryData(
				getPartyList({ page, limit }).queryKey,
			);
			queryClient.setQueryData(
				getPartyList({ page, limit }).queryKey,
				(old: PartyList) => {
					return {
						...old,
						parties: old.parties.filter((p) => p.id !== partyId),
					};
				},
			);
			return { previousData };
		},
		onError: async (_err, _variables, context) => {
			if (!context) return;
			queryClient.setQueryData(
				getPartyList({ page, limit }).queryKey,
				context.previousData,
			);
		},
		onSettled: async () => {
			await queryClient.invalidateQueries({
				queryKey: getPartyList({ page, limit }).queryKey.slice(0, 1),
			});
		},
	});

	return (
		<Card className={"mt-10 w-full"}>
			<CardHeader>
				<CardTitle>Your existing groups</CardTitle>
			</CardHeader>
			<CardContent>
				{isPending ? (
					<div className="space-y-2">
						<Skeleton className="h-4 w-[250px]" />
						<Skeleton className="h-4 w-[200px]" />
					</div>
				) : !data ? (
					<div>
						You're not a member of any groups yet :(
						<br />
						Try creating one...
					</div>
				) : (
					<ul
						className={
							"grid grid-cols-1 gap-2 space-y-4/ md:grid-cols-2 lg:grid-cols-3 min-[1700px]:grid-cols-4"
						}
					>
						{data.parties.map((party) => (
							<li key={party.id} className={"shrink"}>
								<PartyCard party={party} deleteMutation={deletePartyMutation} />
							</li>
						))}
					</ul>
				)}
			</CardContent>
			<CardFooter>
				<div className={"relative flex w-full flex-row"}>
					<Pagination className={""}>
						<PaginationContent>
							<Link
								to={"."}
								search={{
									page: (page ?? 1) - 1,
									limit: limit,
								}}
							>
								<PaginationItem>
									<PaginationPrevious />
								</PaginationItem>
							</Link>
							{page && page > 2 && maxPages > 3 && (
								<PaginationItem>
									<PaginationEllipsis />
								</PaginationItem>
							)}
							{[-2, -1, 0, 1, 2]
								.filter(
									(i) =>
										((page + i >= 1 && Math.abs(i) !== 2) ||
											(page + i === 1 && maxPages <= 3) ||
											(page === 1 && i === 2)) &&
										page + i <= maxPages,
								)
								.map((i) => (
									<Link
										key={i}
										to={"."}
										search={{
											page: (page ?? 1) + i,
											limit: limit,
										}}
									>
										<PaginationItem>
											<PaginationLink isActive={i === 0} href="#">
												{(page ?? 1) + i}
											</PaginationLink>
										</PaginationItem>
									</Link>
								))}

							{page + 1 < maxPages && (
								<PaginationItem>
									<PaginationEllipsis />
								</PaginationItem>
							)}
							<Link
								disabled={page === maxPages}
								to={"."}
								search={{
									page: (page ?? 1) + 1,
									limit: limit,
								}}
							>
								<PaginationItem>
									<PaginationNext />
								</PaginationItem>
							</Link>
						</PaginationContent>
					</Pagination>
					<Select
						defaultValue={"12"}
						onValueChange={(e) => {
							navigate({
								to: ".",
								search: {
									page: page,
									limit: Number(e) ?? limit,
								},
							});
						}}
					>
						<SelectTrigger className="absolute right-0 w-fit">
							<SelectValue />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								<SelectLabel>Items per page</SelectLabel>
								<SelectItem value="12">12</SelectItem>
								<SelectItem value="24">24</SelectItem>
								<SelectItem value="36"> 36</SelectItem>
							</SelectGroup>
						</SelectContent>
					</Select>
				</div>
			</CardFooter>
		</Card>
	);
}

const PartyCard = ({
	party,
	deleteMutation,
}: {
	party: { name: string; createdAt: string; memberCount: number; id: string };
	deleteMutation: UseMutationResult<
		void,
		Error,
		string,
		{ previousData: unknown }
	>;
}) => {
	return (
		<Card className={"flex h-full w-fit/ shrink flex-col"}>
			<CardHeader className={"sm:pb-2 2xl:pb-4"}>
				<CardTitle className={"line-clamp-3"} aria-label={party.name}>
					{party.name}
				</CardTitle>
				{/*<CardDescription>{party.memberCount} members</CardDescription>*/}
				<CardDescription>
					Created {timeSince(new Date(party.createdAt))} ago
				</CardDescription>
			</CardHeader>
			<CardContent
				className={
					"mt-auto flex flex-col flex-wrap justify-between pb-4 sm:flex-row "
				}
			>
				<span
					className={
						"my-auto mr-4 mb-4 whitespace-nowrap text-neutral-600 text-sm sm:mb-2 dark:text-neutral-300"
					}
				>
					Members: {party.memberCount}
				</span>
				<div
					className={
						"mt-auto flex w-full flex-col flex-wrap space-y-2 self-end justify-self-end sm:ml-auto sm:w-fit sm:flex-row sm:flex-nowrap sm:space-x-2 sm:space-y-0 "
					}
				>
					<Link
						to={"/party/$partyId"}
						params={{ partyId: short.fromUUID(party.id) }}
					>
						<Button className={""}>Join</Button>
					</Link>
					<AlertDialog>
						<AlertDialogTrigger asChild>
							<Button variant={"destructive"}>Delete</Button>
						</AlertDialogTrigger>
						<AlertDialogContent>
							<AlertDialogHeader>
								<AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
								<AlertDialogDescription>
									This action cannot be undone. This will permanently delete
									your account and remove your data from our servers.
								</AlertDialogDescription>
							</AlertDialogHeader>
							<AlertDialogFooter>
								<AlertDialogCancel>Cancel</AlertDialogCancel>
								<AlertDialogAction asChild>
									<Button
										onClick={() => {
											deleteMutation.mutateAsync(party.id).catch((e) => {
												toast.error("Error deleting party");
												console.error("error", e);
											});
										}}
										variant={"destructive"}
									>
										Delete
									</Button>
								</AlertDialogAction>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialog>
				</div>
			</CardContent>
			{/*<CardFooter className={'space-x-2'}>*/}
			{/*  */}
			{/*</CardFooter>*/}
		</Card>
	);
};
