import { Outlet, createFileRoute, redirect } from "@tanstack/react-router";

// this layout exists to auth protect these routes
export const Route = createFileRoute("/_app")({
	beforeLoad: ({ context, location }) => {
		if (!context.user && !location.pathname.includes("/recipes/")) {
			throw redirect({ to: "/login" });
		}
	},
	component: () => {
		return <Outlet />;
	},
});
