"use client";

import { useApi } from "@/components/context/ApiContext";
// import api from '@/components/context/ApiContext';
import { Button } from "@/components/ui/button";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import type { paths } from "@repo/apitypes";
import { useQueryClient } from "@tanstack/react-query";
import { Link, createFileRoute } from "@tanstack/react-router";
import type { HTTPError } from "ky";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

// import { Input } from '@mui/base';
// import { Input as Input2 } from '@mui/joy';

// import { createClientComponentClient } from '@supabase/auth-helpers-nextjs';

// import { cookies } from 'next/headers';
// import Link from 'next/link';
// import action from './action';

export const Route = createFileRoute("/_auth/login")({ component: Login });
const formSchema = z.object({
	email: z.string().email(),
	password: z.string(),
});

export default function Login() {
	const queryClient = useQueryClient();
	const { api, setCsrfToken } = useApi();
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		mode: "onBlur",
		reValidateMode: "onChange",
		defaultValues: {
			email: "",
			password: "",
		},
	});

	const handleSubmit = async (data: z.infer<typeof formSchema>) => {
		// e.preventDefault();

		const email = data.email;
		const password = data.password;
		// formAction({ type: 'submit', username, password });

		try {
			const promise = api.post("auth/login", {
				json: { email: email, password },
			});
			const res = await toast.promise(promise, {
				loading: "Logging in...",
				success: "Logged in successfully",
				error: (err: HTTPError) => {
					console.debug(err.response);
					form.setError("root.serverError", {
						type: String(err.response.status),
						message: "Invalid credentials",
					});
					if (err.response.status === 406) {
						return "Invalid credentials";
					}

					return err.response.statusText || "Error logging in";
				},
			});
			if (res.ok) {
				// console.log('setting token ');
				// console.log(res.headers.get('set-xsrf-token'));
				// console.log(res.headers);
				setCsrfToken(
					(
						(await res.json()) as paths["/auth/login"]["post"]["responses"]["200"]["content"]["application/json"]
					).csrfToken ?? "",
				);
				await queryClient.invalidateQueries({ queryKey: ["session"] });
				// window.location.href = '/dashboard';
				// navigate({ to: '/home' });
			}
		} catch (err) {
			console.warn(err);
		}
	};
	// const [state, formAction] = useActionState(action, initialState);
	return (
		<div className="">
			<h2 className="card-title">Login to your account!</h2>

			{/* {state?.error} */}
			<Form {...form}>
				<form onSubmit={form.handleSubmit(handleSubmit)} className="">
					<FormField
						control={form.control}
						name={"email"}
						render={({ field }) => {
							return (
								<FormItem>
									<FormLabel>Email</FormLabel>
									<FormControl>
										<Input {...field} placeholder={"email"} />
									</FormControl>
									<FormMessage />
								</FormItem>
							);
						}}
					/>

					<FormField
						name={"password"}
						control={form.control}
						render={({ field }) => {
							return (
								<FormItem className={"mt-4"}>
									<FormLabel>Password</FormLabel>
									<FormControl>
										<Input
											{...field}
											placeholder="password"
											type={"password"}
											// className='input input-bordered'
											required
										/>
									</FormControl>
									{form.formState.errors.root?.serverError && (
										<p
											className={
												"mt-4 font-medium text-[0.8rem] text-red-500 dark:text-red-900"
											}
										>
											{form.formState.errors.root?.serverError.message}
										</p>
									)}
								</FormItem>
							);
						}}
					/>
					<FormMessage />
					<Button type="submit" className="mt-4">
						Submit
					</Button>
				</form>
			</Form>
			<span className="float-right mt-4">
				<Link className="link link-accent" to="/signup">
					Sign-up
				</Link>
			</span>
		</div>
	);
}
