import { useQueryClient } from "@tanstack/react-query";
import { RouterProvider } from "@tanstack/react-router";
import { useSession } from "./components/context/SessionContext";

function AppRouter({ router }: { router }) {
	const session = useSession();
	//   if (session.user === undefined || session.user === undefined)
	//     return <div>Loading...</div>;
	const queryClient = useQueryClient();
	return (
		<RouterProvider
			router={router}
			context={{ ...session, queryClient: queryClient }}
		/>
	);
}

export default AppRouter;
